import React from 'react';

import Container from 'components/Container/Container';
// import { banner } from 'styles/pages/common.module.scss';
import HeroText from 'components/HeroText/HeroText';
import Layout from 'components/Layout';
import Seo from 'components/Seo';
import TextLayoutContainer from 'components/TextLayoutContainer/TextLayoutContainer';

const TermsPage = () => (
  <Layout fullWidth>
    <Seo title="Όροι χρήσης" />
    <HeroText title="ΟΡΟΙ ΧΡΗΣΗΣ" subtitle="ΟΡΟΙ ΧΡΗΣΗΣ" />
    <Container withPadding>
      <TextLayoutContainer>
        <h2>1. ΕΙΣΑΓΩΓΗ</h2>
        <p>
          Το faidraconcept.gr είναι ένα ηλεκτρονικό κατάστημα διάθεσης
          προϊόντων γυναικείων ενδυμάτων και αξεσουαρ, μέσω Διαδικτύου.
          Το ηλεκτρονικό αυτό κατάστημα ανήκει στην ατομική επιχείρηση Παπαδάτου Μαγδαληνή με Α.Φ.Μ 049190404 και υπάγεται στην Δ.Ο.Υ Αμαλιαδας. Ο
          επισκέπτης/χρήστης αυτού του δικτυακού τόπου καλείται να διαβάσει
          προσεκτικά τους όρους χρήσης. Αυτοί οι όροι ισχύουν σε κάθε συναλλαγή
          μεταξύ του faidraconcept.gr και οποιουδήποτε προσώπου υποβάλλει μία
          ηλεκτρονική παραγγελία για προμήθεια των προϊόντων που διατίθενται σε
          αυτό το ηλεκτρονικό κατάστημα.
        </p>
        <h2>2. ΠΑΡΕΧΟΜΕΝΕΣ ΠΛΗΡΟΦΟΡΙΕΣ / ΠΡΟΪΟΝΤΑ</h2>
        <p>
          Το faidraconcept.gr δεσμεύεται ως προς την εγκυρότητα και ποιότητα
          των πληροφοριών που παρέχονται σε αυτό το δικτυακό τόπο, υπό την
          επιφύλαξη τυχόν τεχνικών ή τυπογραφικών λαθών. Επίσης δεσμεύεται για
          την άριστη ποιότητα των προϊόντων που διατίθενται σε αυτόν το δικτυακό
          τόπο. Σκοπός του faidraconcept.gr είναι κάθε παραγγελία να σας
          ικανοποιεί 100%. Επίσης, σας δίνουμε τη δυνατότητα να επιστρέψετε
          όποιο είδος επιθυμείτε. (βλέπε ενότητα Επιστροφές Προϊόντων).
        </p>{' '}
        <h2>3. ΤΡΟΠΟΙ ΠΛΗΡΩΜΗΣ </h2>
        <p>Διαβάστε   <a href="https://faidraconcept.gr/tropoi-plhromhs/">
          <strong>
            <span>εδώ</span>
          </strong>
        </a> για τους τρόπους πληρωμής</p>
        <h2>4. ΑΣΦΑΛΕΙΑ ΣΥΝΑΛΛΑΓΩΝ </h2>
        <p>
          Όλες οι συναλλαγές που πραγματοποιείτε μέσω του faidraconcept.gr
          διέπονται από το Διεθνές και Ευρωπαϊκό δίκαιο, που ρυθμίζει θέματα
          σχετικά με το ηλεκτρονικό εμπόριο, καθώς επίσης και από το Νόμο περί
          προστασίας των καταναλωτών (Ν. 2251/1994), που ρυθμίζει θέματα σχετικά
          με τις πωλήσεις από απόσταση. 100% Ασφάλεια Ηλεκτρονικών Συναλλαγών.
          Σε περίπτωση που επιλέξετε να πληρώσετε με την πιστωτική σας κάρτα, μεταβένετε αυτόματα σε ασφαλές περιβάλλον της συνεργαζόμενης εταιρείας (Alpha e-Commerce της Alpha Bank) που έχει αναλάβει τη δρομολόγηση των καρτών.
        </p>
        <p>
          Οι πληροφορίες σχετικά με την πιστωτική σας κάρτα είναι 100%
          προστατευμένες και δεν αποκαλύπτονται σε κανέναν, ούτε και στο
          faidraconcept.gr.
        </p>
        <h2>5. ΕΠΙΣΤΡΟΦΕΣ ΠΡΟΪΟΝΤΩΝ </h2>

        <p>To www.faidraconcept.gr στηρίζοντας το Ηλεκτρονικό Εμπόριο σας δίνει τη δυνατότητα, μέσω της πληρότητας των περιγραφών των οποίων αναρτά στις σελίδες του, και των φωτογραφιών των προϊόντων να απολαμβάνετε το προνόμιο της άμεσης επαφής με τα προϊόντα που διαθέτει από την οθόνη του υπολογιστή σας, γρήγορα και εύκολα.

Θέλοντας όμως να καλύψουμε ακόμη περισσότερο τους πελάτες μας, ώστε να αναδείξουμε τα οφέλη της χρήσης του διαδικτύου στις καθημερινές μας αγορές, προσφέρουμε τη δυνατότητα επιστροφής των προϊόντων. Παρακάτω σας παραθέτουμε τους όρους και τους τρόπους επιστροφής μη ελαττωματικών προϊόντων.</p>

        <strong>Όροι Επιστροφής</strong>

        <p>Η ημερομηνία παραλαβής της παραγγελίας σας με την ημερομηνία υποβολής της αίτησης για επιστροφή να μη ξεπερνάει τις 15 ημερολογιακές ημέρες.
Η συσκευασία να μην έχει παραβιαστεί και να βρίσκεται στην αρχική της (εργοστασιακή) κατάσταση.</p>

        <strong>Διαδικασία επιστροφής</strong>

        <p>Αποστέλλετε το δέμα, μέσω του πρακτορείου της SPEEDEX από το οποίο παραλάβατε την παραγγελία σας.Η διεύθυνση στην οποία πρέπει να επιστραφούν τα προϊόντα είναι :</p>

        <p>
          <strong>Faidra Concept, Ερμού 4, Αμαλιάδα,Νομός Ηλείας,Τ.Κ. 27200</strong></p>

        <p><strong>Υπόψη: Κας Παπαδάτου Μαγδαληνής, Τηλ: 26220 28607, Κιν: 6973693665</strong></p>

        <p>

          Έχετε το δικαίωμα να επιστρέψετε ολόκληρη την παραγγελία ή μέρος αυτής, εντός προθεσμίας 15 ημερολογιακών ημερών από την ημερομηνία που την παραλάβετε. Επιστροφή χρημάτων δεν γίνεται και μπορείτε να χρησιμοποιήσετε το πιστωτικό που θα σας δοθεί εντός 3 μηνών από την ημερομηνία αγοράς. Στην περίπτωση αυτή σας επιβαρύνει μόνο το άμεσο κόστος επιστροφής των προϊόντων. </p><p>Στην περίπτωση που ο λόγος της επιστροφής σας αφορά σε λάθος της εταιρείας μας, δεσμευόμαστε να αναλάβουμε το κόστος επιστροφής του προϊόντος.</p>

        <p>Θα θέλαμε να σας επισημάνουμε πως σε περίπτωση την οποία το προϊόν δεν πληροί τους προαναφερθέντες όρους, θα επιστρέφεται στον αποστολέα του με χρέωση του ιδίου.

Για περισσότερες πληροφορίες είμαστε πάντα στη διάθεση σας είτε μέσω τηλεφώνου στο 26220 28607, είτε μέσω e-mail στο contact@faidraconcept.gr</p>

        <strong>Ακύρωση Παραγγελίας</strong>

        <p>Το www.faidraconcept.gr διαθέτει μηχανισμό για την ακύρωση μιας παραγγελίας (ολικά ή μερικά) που έχει ήδη καταχωρηθεί. Για οποιαδήποτε ακύρωση παραγγελίας θα πρέπει να στείλετε e-mail στο contact@faidraconcept.gr ή να επικοινωνήσετε απευθείας μαζί μας στο τηλέφωνο 26220 28607.

<p>Η δυνατότητα αυτή σας προσφέρεται μέχρι τη στιγμή που θα σας ενημερώσουμε για την αποστολή της παραγγελίας σας. Μετά το χρονικό αυτό σημείο δεν είναι δυνατή η ακύρωση της παραγγελίας σας.</p>

          Στην περίπτωση που η υπηρεσία ταχύ μεταφορών σας έχει παραδώσει το δέμα και δεν επιθυμείτε να το κρατήσετε, τότε ισχύουν οι όροι για την επιστροφή των προϊόντων</p>

      </TextLayoutContainer>
    </Container>
  </Layout >
);

export default TermsPage;